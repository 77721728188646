@charset "utf-8";

@font-face {
  font-family: 'iconfont';  /* Project id 3005728 */
  src: url('//at.alicdn.com/t/font_3005728_387pju6drh.woff2?t=1653492017724') format('woff2'),
       url('//at.alicdn.com/t/font_3005728_387pju6drh.woff?t=1653492017724') format('woff'),
       url('//at.alicdn.com/t/font_3005728_387pju6drh.ttf?t=1653492017724') format('truetype');
}

.iconfont {
    font-family: 'iconfont' !important;
    font-size: 0.16rem;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
}

.ing{
  color: rgba(37, 97, 244, 1) !important;
}
.end{
  color: rgba(227, 0, 56, 1) !important;
}


@import 'reset/index';
@import 'from/index';
@import 'animation/index';
@import 'font/index';
@import 'px2rem';
@import 'antd-ui-reset/index';
@import 'antd/dist/antd.css';
