@charset "utf-8";

/*  font type color */
.f-common_color{
  color: rgba(0, 0, 0, 0.65) !important;
}
.f-operating_color{
  color: #1890ff !important;
}
.f-active_color{
  color: #1890ff !important;
}
.f_error_color{
  color: #f5222d !important;
}


/*  border type color */
.f_act_border{
  border-color: #1890ff !important;
}
.f_common_border{
  border-color: #e8e8e8 !important;
}


/*  background type color */
.f_head_background{
  background: #1890ff;
}
.f_origin_background{
  background: #f59a23;
}


/*  定义字体权重 */
.f_fw1 {
  font-weight: 100 !important;
}
.f_fw2 {
  font-weight: 200 !important;
}
.f_fw3 {
  font-weight: 300 !important;
}
.f_fw4 {
  font-weight: 400 !important;
}
.f_fw5 {
  font-weight: 600 !important;
}
.f_fw6 {
  font-weight: 600 !important;
}
.f_fw7 {
  font-weight: 700 !important;
}
.f_fw8 {
  font-weight: 800 !important;
}


/*  定义字体相关样式 */
.f_tal {
  text-align: left;
}

.f_tac {
  text-align: center;
}

.f_tar {
  text-align: right;
}
