@charset "utf-8";

:-moz-placeholder {
    color: rgb(185, 190, 199);
    font-size: 0.13rem;
}

::-moz-placeholder {
    color: rgb(185, 190, 199);
    font-size: 0.13rem;
}

input:-ms-input-placeholder {
    color: rgb(185, 190, 199);
    font-size: 0.13rem;
}

input::-webkit-input-placeholder {
    color: rgb(185, 190, 199);
    font-size: 0.13rem;
}

input {
    color: #606266;
    height: 40px;
    line-height: 40px;
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    display: inline-block;
    font-size: inherit;
    outline: 0;
    padding: 0;
    width: 100%;
    transition: all 0.2s;
    &:hover {
        border-color: #c0c4cc;
    }
    &:focus {
        border-color: #409eff;
        outline: 0;
    }
}

textarea:focus {
    outline: none;
    border-radius: 0;
}
//input[type='checkbox']:checked, input[type='radio']:checked {
//   background : url('~@/assets/images/checked.png') no-repeat center; background-size: 100% auto;
//   border: 1px solid #00CEFF;
//}

// input[type='checkbox']:checked, input[type='radio']:checked {
//   background : url('../../../assets/checked-light.png') no-repeat center; background-size: 100% auto;
//   border: 1px solid #00CEFF;
// }

.f_form_notfocus:focus {
    outline: none;
    border-radius: 4px;
}

button {
    outline-style: none;
    border: 0px;
    background-color: transparent;
    cursor: pointer;
}

.f_btn {
    cursor: pointer;
    line-height: 1.499;
    height: 32px;
    padding: 0 24px;
    font-size: 14px;
    border-radius: 4px;
    color: #333;
    background-color: #d8d8d8;
    border-color: #d8d8d8;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    &:hover {
        color: #fff;
        background-color: #40a9ff;
        border-color: #40a9ff;
    }
}

.f_btn_primary {
    cursor: pointer;
    line-height: 1.499;
    height: 32px;
    padding: 0 24px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    &:hover {
        color: #fff;
        background-color: #40a9ff;
        border-color: #40a9ff;
    }
}

.f_btn_disable {
    line-height: 1.499;
    height: 32px;
    padding: 0 24px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    text-shadow: none;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;

    border: 1px solid;
    border-color: #d9d9d9;
    &:hover {
        cursor: not-allowed;
    }
}

.f_form_label_color {
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.85) !important;
}

.transfer-out {
    input {
        font-size: 0.16rem;
        font-weight: 600;
        color: #ffffff;
        border: none;
        background: none;
        border-bottom: 1px solid #363c58;
        border-radius: 0;
        &:hover,
        &:focus {
            border-color: #455ac4 !important;
        }
    }
    input[type=text]::placeholder {
        font-size: 0.16rem;
        color: #494e67;
    }
}