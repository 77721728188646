@charset "utf-8";

.ant-menu{
    height: 100%;
    min-height: 100%;
    background: rgb(92, 108, 246);
    color: #fff;
    //color: #409EFF !important;
}
.ant-menu-sub.ant-menu-inline{
    background: rgba(92, 108, 246, .8);
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgba(0, 0, 0, 0.3) !important;
    background-color: #409EFF;
}
.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow{
    color: #fff;
}
.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
    color: #fff;
    //background: rgb(74,86,297, .6);
    background: rgba(0, 0, 0, 0.2);
}



.ant-form-item-label > label{
    height: 40px;
}
